<template>
  <div class="hotelJournal">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>酒店日志</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>修改内容</span>
      <el-select
        clearable
        @clear="onClear('cate')"
        v-model="list.logType"
        placeholder="请选择"
      >
        <el-option
          v-for="item in options"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="gethotelJournalList()">查找</el-button>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column align="center" prop="hotelLogId" label="日志ID">
      </el-table-column>
      <el-table-column align="center" prop="strType" label="修改内容">
      </el-table-column>
      <el-table-column align="center" prop="masterName" label="修改人">
      </el-table-column>
      <el-table-column align="center" prop="hotelAddTime" label="修改时间">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            @click="$router.push(`/hotelLogDetails/${row.hotelLogId}`)"
            >查看详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { hotelJournalList, hotelLog } from "../../api/hotelJournal";
export default {
  name: "hotelJournal",
  props: {
    id: {
      type: [Number, String],
    },
  },
  data() {
    return {
      tableData: [],
      pagination: {},
      list: {
        hotelId: Number(this.id),
        currentPage: 1,
        pageSize: 5,
        logType: null,
      },
      options: [
        {
          key: -1,
          value: "全部",
        },
        {
          key: 0,
          value: "新增",
        },
        {
          key: 1,
          value: "上/下架",
        },
        {
          key: 3,
          value: "酒店名称",
        },
        {
          key: 4,
          value: "详细地址",
        },
        {
          key: 5,
          value: "纬度",
        },
        {
          key: 6,
          value: "经度",
        },
        {
          key: 7,
          value: "标签",
        },

        {
          key: 8,
          value: "展示图",
        },
        {
          key: 9,
          value: "轮播图",
        },
        {
          key: 10,
          value: "联系人",
        },
        {
          key: 11,
          value: "客服电话",
        },
        {
          key: 12,
          value: "星级",
        },
        {
          key: 13,
          value: "简介",
        },
        {
          key: 14,
          value: "区域街道",
        },
        {
          key: 15,
          value: "酒店评分",
        },
        {
          key: 16,
          value: "预定规则",
        },
        {
          key: 17,
          value: "退订时间1",
        },
        {
          key: 18,
          value: "退订比例1",
        },
        {
          key: 19,
          value: "退订时间2",
        },
        {
          key: 20,
          value: "退订比例2",
        },
        {
          key: 21,
          value: "退订免时间",
        },
        {
          key: 22,
          value: "所属分类",
        },
        {
          key: 23,
          value: "酒店详情",
        },
        {
          key: 24,
          value: "排序",
        },
      ],
    };
  },
  created() {
    this.gethotelJournalList();
  },
  methods: {
    async gethotelJournalList() {
      const { data } = await hotelJournalList(this.list);
      console.log(data, "日志");
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    onClear() {
      this.list.logType = null;
    },
    handleSizeChange(size) {
      this.list.pageSize = size;
      this.gethotelJournalList();
    },
    handleCurrentChange(current) {
      this.list.currentPage = current;
      this.gethotelJournalList();
    },
  },
};
</script>
<style lang="less" scoped>
.hotelJournal {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    .el-select {
      margin: 0 20px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>